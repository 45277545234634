* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: #1a191d;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

::selection {
    color: white;
    background: #bc60fb;
}



@keyframes vibrateBackgroundMobile {
    0% {
        background-position: center center;
    }

    50% {
        background-position: 47% 54%;
    }

    100% {
        background-position: center center;
    }
}



@keyframes vibrateBackgroundDesktop {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 18px 0;
    }

    100% {
        background-position: 0 0;
    }
}


@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.7;
    }
}